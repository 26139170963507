<template>
  <div v-if="showOverlay === 'fullscreenVideo' && globalVideoUrl" class="fixed bottom-20 md:top-0 right-0 w-full md:w-1/2 h-1/2 z-50 flex flex-row justify-center items-center py-8">
    <div class="flex flex-row absolute -top-5 md:top-12 md:right-12 right-0 z-50 pointer-events-auto">
      <div class=" bg-white rounded-full w-12 h-12 flex flex-row justify-center items-center font-bold border-2 border-black mr-2" @click="closeFullscreenImage">X</div>
    </div>
    <iframe class="w-full h-full" :src=formatVideoLink(globalVideoUrl) title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</template>

<script>
// Mixins
import iosVibrations from '../mixins/iosVibrations.vue';

export default {
  name: 'fullscreenVideo',
  mixins: [iosVibrations],

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    globalVideoUrl() {
      return this.$store.state.learning.globalVideoUrl;
    },
  },

  watch: {
    showOverlay() {
      console.log('hier');
      if (this.showOverlay === 'fullscreenImage') {
        this.dist = 1;
      }
    },
  },

  beforeUnmount() {
    this.$store.commit('showOverlay', null);
  },

  methods: {
    formatVideoLink(link) {
      if (!link.includes('embed')) {
        if (link.includes('.be')) {
          const query = link.split('/').slice(-1).pop();
          if (query) return `https://www.youtube-nocookie.com/embed/${query}`;
        } else if (link.includes('watch?v=')) {
          const query = link.split('v=').slice(-1).pop();
          if (query) return `https://www.youtube-nocookie.com/embed/${query}`;
        }
      }
      return link;
    },

    closeFullscreenImage() {
      this.iosVibrate(0);
      this.$store.commit('setGlobalImageUrl', null);
      this.$store.commit('showOverlay', null);
    },
  },
};
</script>
