<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryMcFrage',
  watch: {
    mcFrage() {
      this.$store.commit('setMcFrage', this.mcFrage);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'network-only',
    });

    const store = useStore();

    const queryMcFrage = gql`
      query($id: ID ) {
        mcFragen(id: $id)  {
          data {
            id
            attributes {
              Frage
              Antwort {
                id
                Antwort
                Richtig
              }
            }
          }
        }
      }
    `;

    const variables = reactive({
      id: computed(() => store.state.learning.selectedMcFrageId),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: mcFrage } = useQuery({
      query: queryMcFrage,
      variables,
      context,
      fetchOnMount: false,
    });
    return { mcFrage };
  },
};
</script>
